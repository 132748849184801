<template>
    <div>
        <span style="margin-right:5px;" v-if="manage" v-b-modal="'leaderboard-history-modal-' + index" @click="activeModalBtn(`leaderboard-history-modal-'${profile._id}`)">
            <feather-icon
                icon="LayersIcon"
                size="18"
            />
        </span>
        <b-modal :id="'leaderboard-history-modal-' + index" hide-footer size="lg" centered title="History" @hidden="resetModal">
            <div class="modal_content">
                
                <!-- {{ profile.leaderboard_profile_request_history }} -->
                <b-table-simple v-if="profile.leaderboard_profile_request_history.length > 0" hover caption-top responsive class="rounded-bottom mb-0">
                    <b-thead head-variant="light">
                        <b-tr>
                        <b-th>Show Profile</b-th>
                        <b-th>Requested at</b-th>
                        <!-- <b-th>Requested by</b-th> -->
                        <b-th>Status</b-th>
                        <b-th>Updated at</b-th>
                        <b-th>Updated by</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <tr v-for="(item,index) in profile.leaderboard_profile_request_history" :key="index">
                            <td class="text-capitalize">
                                {{ item.profileRequest }}
                            </td>
                            <td>
                                <template v-if="item.profileRequestAt">
                                    <span v-if="item.profileRequestAt.$date.$numberLong">
                                        {{ Helpers.formatDateTimeFromISODate(item.profileRequestAt.$date.$numberLong, 'DD MMM YYYY') }}
                                    </span>
                                    <span v-else>-</span>
                                </template>
                                <template v-else>
                                    -
                                </template>
                            </td>
                            <!-- <td>
                                <template v-if="item.requested_by">
                                    {{ item.requested_by.name }}
                                </template>
                            </td> -->
                            <td class="text-capitalize">
                                <span v-if="item.profileRequestStatus == 'accepted'">
                                    Approved
                                </span>
                                <span v-else>
                                    {{ item.profileRequestStatus }}
                                </span>
                            </td>
                            <td>
                                {{ item.profileRequestUpdatedAt?item.profileRequestUpdatedAt:'-' }}
                            </td>
                            <td>
                                <template v-if="item.updated_by">
                                {{ item.updated_by.name? item.updated_by.name:'-' }}
                                </template>
                                <template v-else>
                                    -
                                </template>
                            </td>
                        </tr>
                    </b-tbody>
                </b-table-simple>
                
            </div>
        </b-modal>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BOverlay,
    BLink,
    BBadge,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTableSimple,
    BThead,
    BFormCheckbox,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    BCardHeader,
    BCardBody,
    BSpinner,
} from "bootstrap-vue";
import axios from "axios";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import * as Helpers from '../Helpers';
export default {
    components: {
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BOverlay,
        BLink,
        BBadge,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BPagination,
        BTableSimple,
        BThead,
        BFormCheckbox,
        BTr,
        BTh,
        BTd,
        BTbody,
        BTfoot,
        BCardHeader,
        BCardBody,
        BSpinner,
    },
    data() {
        return {
            baseVuePath: "@view/src/views/components/themes/",
            baseReactNativePath: "@src/components/themes/",
            vuePath: "@view/src/views/components/themes/",
            reactNativePath: "@src/components/themes/",
            errorMsg: "",
            loading: false,
            alertMessageToast: "",
            record: {},
            Helpers,
            activeModalName: null,
        };
    },
    computed: {
        hasError() {
            return this.errorMsg != "";
        },
    },
    watch: {
    },
    methods: {
        activeModalBtn(modalName){
            this.activeModalName = modalName;
        },
        showToast(variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Notification",
                    icon: "BellIcon",
                    text: this.alertMessageToast,
                    variant,
                },
            });
        },
        clearErrorMsg() {
            this.errorMsg = "";
        },
        resetModal() {
            this.errorMsg = "";
        },
        async saveBtn(data) {
            let status = await this.checkValidation();
            if (!status) {
                return;
            }

            this.record.profileRequestStatus = data;
            this.loading = true;
            try {
                const resp = await axios.put(process.env.VUE_APP_API_URL + "/leaderboard/status/"+this.profile._id,
                    this.record,
                    {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("_t"),
                        },
                    });
                this.alertMessageToast = resp.data.message;
                this.showToast("success");
                this.$emit('emitStatusUpdated');
                this.$bvModal.hide(`leaderboard-history-modal-${this.index}`);
                this.loading = false;
            } catch (error) {
                this.errorMsg = Helpers.catchFunction(error);
                this.loading = false;
                this.alertMessageToast = this.errorMsg;
                this.showToast("danger");
            }
        },
        checkValidation() {
            if (!this.record.note && this.record.profileRequestStatus == 'declined') {
                this.errorMsg = "Note field is required."
                return;
            }
            return "ok";
        },
    },
    props: [
        "manage",
        "profile",
        "index",
    ],
};
</script>
